import md5 from '../utils/md5'
import dateUtils from '../utils/dateUtils'
import ajaxConfig from './config'

export default {
  typeOf(obj) {
    const toString = Object.prototype.toString
    const map = {
      '[object Boolean]': 'boolean',
      '[object Number]': 'number',
      '[object String]': 'string',
      '[object Function]': 'function',
      '[object Array]': 'array',
      '[object Date]': 'date',
      '[object RegExp]': 'regExp',
      '[object Undefined]': 'undefined',
      '[object Null]': 'null',
      '[object Object]': 'object',
    }
    return map[toString.call(obj)]
  },

  /**
   * 对象中日期转时间戳
   * @param {*} obj
   */
  convertTimeToTimestamp(obj) {
    for (var attr in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(attr)) {
        if (obj[attr] instanceof Date) {
          obj[attr] = obj[attr].getTime()
        } else if (
          typeof obj[attr] == 'string' ||
          obj[attr] instanceof String
        ) {
          let regExp =
            /^\d{4}-\d{2}-\d{2}(\s+(2[0-3]|[01][0-9]):([0-5][0-9])(:([0-5][0-9]))?)?$/
          if (regExp.test(obj[attr])) {
            obj[attr] = new Date(obj[attr]).getTime()
          }
        } else if (typeof obj[attr] == 'object') {
          this.convertTimeToTimestamp(obj[attr])
        }
      }
    }
  },

  getValue(obj) {
    var type = this.typeOf(obj)
    var str = ''

    switch (type) {
      case 'object':
      case 'array':
        str = JSON.stringify(obj, (key, value) => {
          if (obj[key] instanceof Date) {
            // return dateUtils.format(obj[key], 'yyyy-MM-dd hh:mm:ss')
            return dateUtils.timestamp(obj[key])
          }
          return value
        })
        break
      case 'date':
        // str = dateUtils.format(obj, 'yyyy-MM-dd hh:mm:ss')
        str = dateUtils.timestamp(obj)
        break
      default:
        str = obj
        break
    }
    return str
  },
  convertValue(obj) {
    var type = this.typeOf(obj)
    var str = ''
    switch (type) {
      case 'date':
        // str = dateUtils.format(obj, 'yyyy-MM-dd hh:mm:ss')
        str = dateUtils.timestamp(obj)
        break
      default:
        str = obj
        break
    }
    return str
  },
  /**
   *
   * @param {*} testStr
   * @returns
   */
  iGetInnerText(testStr) {
    var resultStr = testStr
    resultStr = resultStr.replace(/[\r\n]/g, '') //去掉回车换行
    resultStr = resultStr.replace(/\t/g, '') //去掉回车换行
    // resultStr = resultStr.replace(/\ +/g, ''); //去掉空格
    resultStr = resultStr.replace(/ +/g, '') //去掉空格
    resultStr = resultStr.replace(/[ ]/g, '') //去掉空格

    return resultStr
  },

  /**
   * 计算签名
   * @param {*} dic
   * @returns
   */
  sign(dic) {
    var param = []
    for (var key in dic) {
      param.push({
        key: key,
        value: dic[key],
      })
    }
    // 参数排序
    param.sort((a, b) => {
      return a.key.toLowerCase() > b.key.toLowerCase() ? 1 : -1
    })

    // 拼接字符串
    var str = ''
    param.forEach((e) => {
      if (e.value != null) {
        str += '&' + e.key + '=' + this.getValue(e.value)
      }
    })

    if (str.length > 0) str = str.slice(1)
    var tokenSecret = ajaxConfig.getSecretKey() || 'www.yesdotnet.com'
    var _parmStr = this.iGetInnerText(str + tokenSecret)
    if (ajaxConfig.option.writeLog == true) {
      console.log('parmStr:' + _parmStr)
    }

    var sign = md5.hex_md5(_parmStr)

    return sign
  },
}
