export default {
  //获取cookie、
  getCookie: function (name) {
    var arr,
      reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
    // eslint-disable-next-line
    if ((arr = document.cookie.match(reg))) return arr[2]
    else return null
  },
  /**
   * 设置cookie
   * @param {*} c_name 参数名
   * @param {*} value 值
   * @param {} expiredays 有效期,天
   * @param {} path Cookie的Path '/'
   */
  setCookie: function (c_name, value, expiredays) {
    var exdate = new Date()
    exdate.setDate(exdate.getDate() + expiredays)
    document.cookie =
      c_name +
      '=' +
      escape(value) +
      ';path=/' +
      (expiredays == null ? '' : ';expires=' + exdate.toGMTString())
  },
  //删除cookie
  delCookie: function (name) {
    var exp = new Date()
    exp.setDate(exp.getDate() - 1)
    var cval = this.getCookie(name)
    if (cval !== null) {
      document.cookie =
        name + '=' + cval + ';path=/;expires=' + exp.toGMTString()
    }
  },
}
