import ajaxCommon from './ajax-common'
import ajaxConfig from './config'

const common = {
  getXMLHttp() {
    // 先声明一个异步请求对象
    var xmlHttpReg = null
    if (window.ActiveXObject) {
      // 如果是IE
      xmlHttpReg = new ActiveXObject('Microsoft.XMLHTTP') // eslint-disable-line
    } else if (window.XMLHttpRequest) {
      xmlHttpReg = new XMLHttpRequest() // 实例化一个xmlHttpReg
    }
    return xmlHttpReg
  },
  goLogin: function (message) {
    if (ajaxConfig.option && ajaxConfig.option.actionLogin)
      ajaxConfig.option.actionLogin(message)
    else console.log('跳转到登录！')
  },
}
export default {
  get(pageContext, url, params) {
    var me = this
    var param = Object.assign(
      {
        _t: Date.parse(new Date()) / 1000,
      },
      params
    )
    var xmlHttpReg = common.getXMLHttp()
    // 如果实例化成功,就调用open()方法,就开始准备向服务器发送请求
    if (xmlHttpReg != null) {
      var res = ''
      var p = []
      var parmsArray = []
      for (var key in param) {
        // p.push({
        //   key: key,
        //   value: param[key],
        // })
        p[key] = param[key]
        parmsArray.push(key + '=' + param[key])
      }
      // var everyOne = false
      // var id = ''
      // if (pageContext && pageContext.$route && pageContext.$route.meta) {
      //   id = pageContext.$route.meta.id
      //   if (pageContext.$route.meta.everyOne) {
      //     everyOne = pageContext.$route.meta.everyOne
      //     delete pageContext.$route.meta.everyOne
      //   }
      // }

      var time = Date.parse(new Date()) / 1000
      var guid = Math.random().toString(36).slice(-8)
      p['time'] = time
      p['guid'] = guid
      // var url = config.baseURL + config.url

      var rurl =
        ajaxConfig.serverHost + '/api' + url + '?' + parmsArray.join('&')
      var pathname = rurl.match(/\/api\/[^\?]+/)[0].toLowerCase()
      p['url'] = pathname
      // 计算签名
      // var sign = ajaxCommon.sign(p,true,everyOne);
      var sign = ajaxCommon.sign(p)

      xmlHttpReg.open('get', rurl, false)
      xmlHttpReg.onreadystatechange = function () {
        // 设置回调函数
        if (xmlHttpReg.readyState === 4) {
          // 4代表执行完成
          // if (xmlHttpReg.status === 200) { // 200代表执行成功
          // 将xmlHttpReg.responseText的值赋给ID为resText的元素
          // document.getElementById("resText").innerHTML = xmlHttpReg.responseText;
          res = xmlHttpReg.responseText
          // }
        }
      }

      xmlHttpReg.setRequestHeader(
        'Authorization',
        'Bearer ' + ajaxConfig.getToken()
      )
      xmlHttpReg.setRequestHeader('time', time)
      xmlHttpReg.setRequestHeader('guid', guid)
      xmlHttpReg.setRequestHeader('sign', sign)
      xmlHttpReg.send(null)

      var result = JSON.parse(res)
      if (result.error == 4002) {
        var data = {
          RefresshToken: ajaxConfig.getRefreshToken(),
          // random: random,
        }
        var _jsonStr = me.get(null, '/user/RefreshToken', data)
        var resultObj = JSON.parse(_jsonStr)
        if (resultObj.error === 0) {
          var token = resultObj.data.token
          var refreshToken = resultObj.data.refreshToken
          var secretKey = resultObj.data.secretKey
          // console.log(resultObj)
          ajaxConfig.setTokenData(token, refreshToken, secretKey)
          //重新执行当前未执行成功的请求并返回
          return me.get(pageContext, url, params)
        } else {
          // this.goLogin('用户信息过期')
          common.goLogin()
          console.log('跳转到登录')
          return false
        }
      }
      return res
    }
  },
}
