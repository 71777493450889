import axios from 'axios'
import ajaxConfig from './config'
import ajaxCommon from './ajax-common'

export default {
  //请求队列
  requests: [],
  // 正在刷新token
  isTokenRefreshing: false,
  isGG: false,
  create() {
    // console.log('ajaxConfig.serverHost：' + ajaxConfig.serverHost)
    var context = axios.create({
      baseURL: ajaxConfig.serverHost + '/api',
      timeout: 30000,
    })
    // 处理请求
    context.interceptors.request.use(
      this.axiosRequestConfig,
      this.axiosRequestError
    )

    // 处理响应
    context.interceptors.response.use(this.axiosResponseSuccess, (err) => {
      // var b = this.isTokenRefreshing
      if (err.response) {
        switch (err.response.status) {
          case 400:
            {
              console.log(err.response)
              // option = handleAPIErr(err)
              switch (err.response.data.error) {
                case 4002: {
                  // Token过期
                  // 刷新token
                  if (this.isTokenRefreshing) {
                    return new Promise((resolve) => {
                      //这里加入的是一个promise的解析函数，将响应的config配置对应解析的请求函数存到requests中，等到刷新token回调后再执行
                      this.requests.push(() => {
                        resolve(axios(err.response.config))
                      })
                    })
                  } else {
                    this.isTokenRefreshing = true
                    // 刷新token
                    var ajax = this.create()
                    // var config = this.getconfig(pageContext)
                    // config.params = param
                    // var random = Math.random().toString(36).slice(-8)
                    var params = {
                      RefresshToken: ajaxConfig.getRefreshToken(),
                      // random: random,
                    }
                    return ajax
                      .get('/user/RefreshToken', { params })
                      .then((res) => {
                        if (res.result.error === 0) {
                          var token = res.data.token
                          var refreshToken = res.data.refreshToken
                          var secretKey = res.data.secretKey
                          console.log(res.data)
                          ajaxConfig.setTokenData(
                            token,
                            refreshToken,
                            secretKey
                          )
                          //执行requests队列中的请求，（requests中存的不是请求参数，而是请求的Promise函数，这里直接拿来执行就好）
                          this.requests.forEach((run) => run())
                          //将请求队列置空
                          this.requests = []
                          this.isTokenRefreshing = false
                          //重新执行当前未执行成功的请求并返回
                          console.log(err.response.config)
                          var newConfig = this.axiosRequestConfig(
                            err.response.config
                          )
                          console.log(newConfig)
                          return axios(newConfig)
                        } else {
                          this.goLogin('用户信息过期')
                          return false
                        }
                      })
                      .catch((err) => {
                        this.goLogin('用户信息过期')
                        return false
                      })
                  }
                }
              }
            }
            break
        }

        this.showError({
          title: '请求失败',
          message: err.response.data.message,
        })
        return Promise.reject(err)
      } else {
        this.showError({
          title: '请求失败',
          message: err + '',
        })
        return Promise.reject(err)
      }
    })

    if (this.isGG == false) {
      this.isGG = true
      // 处理响应
      axios.interceptors.response.use(this.axiosResponseSuccess, (err) => {
        return Promise.reject(err)
      })
    }

    return context
  },

  register(_store, _option) {
    ajaxConfig.register(_store, _option)
  },

  axiosRequestConfig(config) {
    var p = []
    var time = Date.parse(new Date()) / 1000
    var guid = Math.random().toString(36).slice(-8)
    if (!config.params) config.params = {}
    /*
    Regex r = new Regex(@"/api/\S+", RegexOptions.IgnoreCase); // 定义一个Regex对象实例
            Match m = r.Match(url); // 在字符串中匹配
            string local = m.Value;
            return local.ToLower();
    */
    var url = config.baseURL + config.url
    var pathname = url.match(/\/api\/[^\?]+/)[0].toLowerCase()

    p['url'] = pathname
    p['time'] = time
    p['guid'] = guid
    for (var key2 in config.params) {
      // p.push({
      //   key: key2,
      //   value: config.params[key2],
      // })
      p[key2] = config.params[key2]
    }

    if (config.method === 'post') {
      // 处理时间，请求前时间转换为时间戳
      ajaxCommon.convertTimeToTimestamp(config.data)
      
      // debugger
      if (ajaxCommon.typeOf(config.data) === 'string') {
        p['postdata'] = config.data
        config.data = JSON.parse(config.data)
      } else {
        p['postdata'] = JSON.stringify(config.data)
      }
    }

    // var everyOne = false
    // if (config.ajaxTag && config.ajaxTag.everyOne)
    //   everyOne = config.ajaxTag.everyOne

    var sign = ajaxCommon.sign(p)

    // dictionary.Add("time", time);
    // dictionary.Add("guid", guid);
    // dictionary.Add("lcoalpath", localPath);
    config.headers['Authorization'] = 'Bearer ' + ajaxConfig.getToken()
    // config.headers['token'] = tokenData.token
    config.headers['time'] = time
    config.headers['guid'] = guid
    config.headers['sign'] = sign
    return config
  },
  axiosRequestError(error) {
    return Promise.reject(error)
  },

  axiosResponseSuccess(response) {
    response.result = response.data
    response.data = response.data.data

    if (response.result.error !== 0) {
      switch (response.result.error) {
        case 4002: // Token过期
          {
            // 刷新token
            if (this.isTokenRefreshing) {
              return new Promise((resolve) => {
                //这里加入的是一个promise的解析函数，将响应的config配置对应解析的请求函数存到requests中，等到刷新token回调后再执行
                this.requests.push(() => {
                  resolve(axios(response.config))
                })
              })
            } else {
              this.isTokenRefreshing = true
              // 刷新token
              var ajax = this.create()
              // var config = this.getconfig(pageContext)
              // config.params = param
              // var random = Math.random().toString(36).slice(-8)
              var params = {
                RefresshToken: ajaxConfig.getRefreshToken(),
                // random: random,
              }
              return ajax
                .get('/user/RefreshToken', { params })
                .then((res) => {
                  if (res.result.error === 0) {
                    var token = res.data.token
                    var refreshToken = res.data.refreshToken
                    var secretKey = res.data.secretKey
                    ajaxConfig.setTokenData(token, refreshToken, secretKey)
                    //执行requests队列中的请求，（requests中存的不是请求参数，而是请求的Promise函数，这里直接拿来执行就好）
                    requests.forEach((run) => run())
                    //将请求队列置空
                    requests = []
                    this.isTokenRefreshing = false
                    //重新执行当前未执行成功的请求并返回
                    return axios(config)
                  } else {
                    this.goLogin('用户信息过期')
                    return false
                  }
                })
                .catch((err) => {
                  this.goLogin('用户信息过期')
                  return false
                })
            }
          }
          break
        case 4001: //没有登录
        case 4003: // 刷新token过期
          {
            console.log(response.result)
            this.goLogin(response.result.message)
          }
          break
      }
    } else {
      // if (response.result && response.result.tokenSecret) {
      //   // 密钥有更新了  updateSecretKey
      //   ajaxConfig.setTokenSecret(
      //     response.result.rid,
      //     response.result.tokenSecret
      //   )
      // }
    }

    return response
  },

  goLogin: function (message) {
    if (ajaxConfig.option && ajaxConfig.option.actionLogin)
      ajaxConfig.option.actionLogin(message)
    else console.log('跳转到登录！')
  },
  showError: function (option) {
    if (ajaxConfig.option && ajaxConfig.option.actionError)
      ajaxConfig.option.actionError(option)
    else console.log('请求发生错误：' + JSON.stringify(option))
  },
  axiosResponseError(err) {
    var option = {}
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          {
            console.log(err.response)
            // option = handleAPIErr(err)
            if (err.response.data.error == 4002) {
              // Token过期 刷新token
              if (this.isTokenRefreshing) {
                return new Promise((resolve) => {
                  //这里加入的是一个promise的解析函数，将响应的config配置对应解析的请求函数存到requests中，等到刷新token回调后再执行
                  this.requests.push(() => {
                    resolve(axios(this.axiosRequestConfig(err.response.config)))
                  })
                })
              } else {
                this.isTokenRefreshing = true
                // 刷新token
                var ajax = this.create()
                // var config = this.getconfig(pageContext)
                // config.params = param
                // var random = Math.random().toString(36).slice(-8)
                var params = {
                  RefresshToken: ajaxConfig.getRefreshToken(),
                  // random: random,
                }
                return ajax
                  .get('/user/RefreshToken', { params })
                  .then((res) => {
                    if (res.result.error === 0) {
                      var token = res.data.token
                      var refreshToken = res.data.refreshToken
                      var secretKey = res.data.secretKey
                      ajaxConfig.setTokenData(token, refreshToken, secretKey)
                      //执行requests队列中的请求，（requests中存的不是请求参数，而是请求的Promise函数，这里直接拿来执行就好）
                      requests.forEach((run) => run())
                      //将请求队列置空
                      requests = []

                      this.isTokenRefreshing = false
                      //重新执行当前未执行成功的请求并返回
                      console.log(err.response.config)
                      var newConfig = this.axiosRequestConfig(
                        err.response.config
                      )
                      console.log(newConfig)
                      return axios(newConfig)
                    } else {
                      this.goLogin('用户信息过期')
                      return false
                    }
                  })
                  .catch((err) => {
                    this.goLogin('用户信息过期')
                    return false
                  })
              }
            } else if (
              3000 < err.response.data.error &&
              err.response.data.error < 4000
            ) {
              // 重新登录
              this.goLogin()
            } else {
              option.title = '请求失败'
              option.message =
                '错误代码：' +
                err.response.data.error +
                '<br/>错误信息：<br/ >' +
                err.response.data.message
            }
            if (option === null) return Promise.reject(err)
          }
          break
        case 401:
          {
            option.title = 'HTTP：401'
            option.message = '未授权，请求失败'
          }
          break
        case 403:
          {
            option.title = 'HTTP：403'
            option.message = '权限不足，拒绝访问'
          }
          break
        case 404:
          {
            option.title = 'HTTP：404'
            option.message = `请求地址无效：${err.response.config.url}`
          }
          break
        case 408:
          {
            option.title = 'HTTP：408'
            option.message = '请求超时'
          }
          break
        case 500:
          {
            option.title = 'HTTP：500'
            option.message = '服务器内部错误'
          }
          break
        case 501:
          {
            option.title = 'HTTP：501'
            option.message = '服务未实现'
          }
          break
        case 502:
          {
            option.title = 'HTTP：502'
            option.message = '网关错误'
          }
          break
        case 503:
          {
            option.title = 'HTTP：503'
            option.message = '服务不可用'
          }
          break
        case 504:
          {
            option.title = 'HTTP：504'
            option.message = '网关超时'
          }
          break
        case 505:
          {
            option.title = 'HTTP：505'
            option.message = 'HTTP版本不受支持'
          }
          break
        default:
          option.title = 'HTTP：' + err.response.status
          option.message = '请求接口失败：' + err.message
      }
    } else {
      option.title = '请求失败'
      option.message = '网络不可用,请检查网络连接'
    }
    // Vue.prototype.$Modal.error({
    //     title: title,
    //     width:'80%',
    //     content: err.message,
    //     okText: '确定'
    // });
    this.showError(option)
    return Promise.reject(err) // eslint-disable-line no-undef
  },
}
