import dayjs from 'dayjs'

const common = {
  padLeftZero(str) {
    return ('00' + str).substr(str.length)
  },
}

export default {
  /**
   * 格式化日期时间
   */
  format(date, fmt) {
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        (date.getFullYear() + '').substr(4 - RegExp.$1.length)
      )
    }

    let obj = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'h+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds(),
    }

    for (let key in obj) {
      if (new RegExp(`(${key})`).test(fmt)) {
        let str = obj[key] + ''
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1 ? str : common.padLeftZero(str)
        )
      }
    }
    return fmt
  },

  /**
   * 基于dayjs日期格式化,时间戳(毫秒)转日期
   * @param {*} date
   * @param {*} format
   * @returns
   */
  dateFormat(date, format) {
    format = format || 'YYYY-MM-DD HH:mm'
    if (!date) return ''
    return dayjs(date).format(format)
  },
  /**
   * 基于dayjs日期格式化,日期转时间戳(毫秒)
   * @param {*} val
   * @returns
   */
  timestamp(val) {
    return dayjs(val).valueOf()
  },

  /**
   * 获取当前月的第一天
   */
  getCurrentMonthFirst() {
    var date = new Date()
    date.setDate(1)
    return date
  },
  /**
   * 获取当前月的最后一天
   */
  getCurrentMonthLast() {
    var date = new Date()
    var currentMonth = date.getMonth()
    var nextMonth = ++currentMonth
    var nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1)
    var oneDay = 1000 * 60 * 60 * 24
    return new Date(nextMonthFirstDay - oneDay)
  },
  //获取当前时间前一小时
  getCurrentLastHour() {
    var frontOneHour = new Date(new Date().getTime() - 1 * 60 * 60 * 1000)
    return frontOneHour
  },
  /**
   * 获得当前日期，时分秒为0
   * @returns
   */
  getToday() {
    var today = new Date()
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0')
    var yyyy = today.getFullYear()
    return new Date(yyyy + '-' + mm + '-' + dd + ' 00:00:00')
  },
}
