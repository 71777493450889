<template>
  <div style="height:100%;">
    <div id="subcontent" class="gz-subcontent"></div>
  </div>
</template>

<script>
/* eslint-disable */
import NProgress from 'nprogress'
import qiankunHelper from "../qiankunHelper";
// import { globalStore } from '../store'
// import validate from '../mixins/validatelogin';
// import { mapGetters } from 'vuex'

export default {
  name: 'empty',
  components: {
  },
  mixins: [],
  data () {
    return {
      isLoading: true,
      microApps: [],
      currentApp: null
    }
  },
  created () {


    NProgress.start()
    this.microApps = this.$store.getters['global/microApps'].map(item => {
      return {
        ...item,
        container: '#subcontent', // 子应用挂载的div
        // props: {
        //   routerBase: item.activeRule, // 下发基础路由
        //   getGlobalState: globalStore.getGlobalState // 下发getGlobalState方法
        // }
      }
    })
    this.loadApp()


  },
  computed: {
    // ...mapGetters({
    //   app: 'global/getCurrentMicroAPP'
    // })
  },
  watch: {
    isLoading (val) {
      if (val) {
        NProgress.start()
      } else {
        this.$nextTick(() => {
          NProgress.done()
        })
      }
    }
  },
  methods: {

    loadApp () {
      if (this.currentApp != null)
        this.currentApp.unmount()
      var app = this.getCurrentAPP()
      
      this.currentApp = qiankunHelper.loadMicroApp(app)
      this.isLoading = false
    },
    getCurrentAPP () {
      var url = window.location.pathname;
      var app = this.microApps.find(item => url.indexOf(item.activeRule) == 0);
      return app;
    },
    listenRouterChange () {
      // const _wr = function (type) {
      //   const orig = history[type]
      //   return function () {
      //     const rv = orig.apply(this, arguments)
      //     const e = new Event(type)
      //     e.arguments = arguments
      //     window.dispatchEvent(e)
      //     return rv
      //   }
      // }
      // history.pushState = _wr('pushState')

      // window.addEventListener('pushState', this.bindCurrent)
      // window.addEventListener('popstate', this.bindCurrent)

      // this.$once('hook:beforeDestroy', () => {
      //   window.removeEventListener('pushState', this.bindCurrent)
      //   window.removeEventListener('popstate', this.bindCurrent)
      // })
    }
  },
  beforeDestroy () {
    if (this.currentApp != null)
      this.currentApp.unmount();
  },
  mounted () {
    this.listenRouterChange()
  }
}
</script>
<style lang="scss" scoped>
</style>

<style lang="scss">
</style>

