const config = {
  store: null,
  option: null,
  serverHost: '',
  register(_store, _options) {
    this.store = _store
    this.option = _options
    // console.log('_store.state.global.serverHost:'+_store.state.global.serverHost)
    this.serverHost = _store.state.global.serverHost
  },
  /**
   * 获得Token
   * @returns
   */
  getToken() {
    // var val=localStorage.getItem('token')
    // return val
    return this.store.state.global.user.token
  },
  /**
   * 获得刷新Token
   * @returns
   */
  getRefreshToken() {
    // var val=localStorage.getItem('refresh-token')
    // return val
    return this.store.state.global.user.refreshToken
  },
  /**
   * 获得私钥
   * @returns
   */
  getSecretKey() {
    // var val=localStorage.getItem('guid')||'www.yesdotnet.com'
    // return val
    return this.store.state.global.user.secretKey
  },
  /**
   * 设置Token信息
   * @param {*} token
   * @param {*} refreshToken
   * @param {*} secretKey
   */
  setTokenData(token, refreshToken, secretKey) {
    // this.store.dispatch('global/updateSecretKey', {
    //   secretID: secretID,
    //   secretKey: secretKey,
    // })

    this.store.dispatch('global/updatetoken', {
      token: token,
      refreshToken: refreshToken,
      secretKey: secretKey
    })
    // localStorage.setItem('token',token)
    // localStorage.setItem('refresh-token',refreshToken)
    // localStorage.setItem('guid',secretKey)
  },
}

export default config
