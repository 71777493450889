import ajaxAxios from './ajax-axios'
import ajaxConfig from './config'
import Promise from 'Promise'
export default {
  _ajaxRemote: null,
  init(_store, _option) {
    ajaxAxios.register(_store, _option)
    this._ajaxRemote = ajaxAxios.create()
  },
  getconfig(pageContext) {
    // var id = ''
    // if (pageContext && pageContext.$route && pageContext.$route.mate)
    //   id = pageContext.$route.meta.id

    var config = {
      headers: {
        // client: ajaxConfig.store.state.global.client,
        // businesscode: ajaxConfig.store.state.global.businessCode,
      },
    }
    if (pageContext && pageContext.$route && pageContext.$route.meta) {
      if (pageContext.$route.meta.reqtimeout) {
        config.timeout = pageContext.$route.meta.reqtimeout
        delete pageContext.$route.meta.reqtimeout
      }
      if (pageContext.$route.meta.everyOne) {
        config.ajaxTag = {
          everyOne: pageContext.$route.meta.everyOne,
        }
        delete pageContext.$route.meta.everyOne
      }
    }
    return config
  },

  get(pageContext, url, data) {
    var config = this.getconfig(pageContext)
    config.params = data
    return this._ajaxRemote.get(url, config)
  },

  delete(pageContext, url, data) {
    var config = this.getconfig(pageContext)
    config.params = data
    return this._ajaxRemote.delete(url, config)
  },

  post(pageContext, url, data, param) {
    var config = this.getconfig(pageContext)
    config.params = param
    return this._ajaxRemote.post(url, data, config)
  },
  getDownLoad(pageContext, url, param) {
    var config = this.getconfig(pageContext)
    config.params = param
    var req = this._ajaxRemote.get(
      url,
      Object.assign({
          responseType: 'blob',
        },
        config
      )
    )
    return this.downloadBase(req)
  },
  postDownLoad(pageContext, url, data, param) {
    var config = this.getconfig(pageContext)
    config.params = param
    var req = this._ajaxRemote.post(
      url,
      data,
      Object.assign({
          responseType: 'blob',
        },
        config
      )
    )
    return this.downloadBase(req)
  },
  downloadBase(req) {
    return new Promise((resolve, reject) => {
      req
        .then((res) => {
          // console.log('req.THEN')
          if (!res) {
            // this.$message.error('下载模板文件失败')
            ajaxConfig.option.actionError({
              title: '错误',
              message: '下载模板文件失败',
            })
            reject('下载文件失败')
            return false
          }
          // 将二进制流转为blob
          // const blob = new Blob([response.result], {
          //   type: 'application/octet-stream',
          // })
          const blob = new Blob([res.result], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
          })
          if (typeof window.navigator.msSaveBlob !== 'undefined') {
            // 兼容IE，window.navigator.msSaveBlob：以本地方式保存文件
            window.navigator.msSaveBlob(blob, decodeURI(filename))
          } else {
            const downloadElement = document.createElement('a')
            const href = window.URL.createObjectURL(blob)
            //从response的headers中获取filename, 后端response.setHeader("Content-disposition", "attachment; filename=xxxx.docx") 设置的文件名;
            let contentDisposition = res.headers['content-disposition']
            let patt = new RegExp('filename=([^;]+\\.[^\\.;]+);*')
            let result = patt.exec(contentDisposition)
            let filename = decodeURI(result[1])
            // let filename = '模块配置.json';
            downloadElement.style.display = 'none'
            downloadElement.href = href
            downloadElement.download = filename //下载后文件名
            // 兼容：某些浏览器不支持HTML5的download属性
            if (typeof downloadElement.download === 'undefined') {
              tempLink.setAttribute('target', '_blank')
            }
            document.body.appendChild(downloadElement)
            downloadElement.click() //点击下载
            document.body.removeChild(downloadElement) //下载完成移除元素
            window.URL.revokeObjectURL(href) //释放掉blob对象
            resolve('下载成功')
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  all(requests) {
    this._ajaxRemote.all(requests)
  },
  everyOne(pageContext) {
    pageContext.$route.meta.everyOne = true
    return this
  },
}