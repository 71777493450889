export default {
  globalRegister: (store, props = {}) => {
    if (!store || !store.hasModule) {
      return
    }

    // 获取初始化的state
    const initState = (props.getGlobalState && props.getGlobalState()) || {
      client: '',
      serverHost: '',
      businessCode: '',
      commonMicroApps: [],
      userMicroApps: [],
      userAuthorize: [],
      menu: [],
      openPages: [],
      title: 'YESWEB开发框架',
      user: {
        token: '',
        refreshToken: '',
        secretKey: '',
      },
    }
    // 将父应用的数据存储到子应用中，命名空间固定为global
    if (!store.hasModule('global')) {
      const globalModule = {
        namespaced: true,
        state: initState,
        actions: {
          // 子应用改变state并通知父应用
          setGlobalState({ commit }, payload) {
            commit('setGlobalState', payload)
            commit('emitGlobalState')
          },
          // 初始化，只用于mount时同步父应用的数据
          initGlobalState({ commit }, payload) {
            commit('setGlobalState', payload)
          },
          updatetoken({ commit }, payload) {
            commit('updatetoken', payload)
            commit('emitGlobalState')
          },
          updateUser({ commit }, data) {
            commit('updateUser', data)
            commit('emitGlobalState')
          },
          updateBusinessCode({ commit }, businessCode) {
            commit('updateBusinessCode', businessCode)
            commit('emitGlobalState')
          },
          updateMenu({ commit }, data) {
            commit('updateMenu', data)
            commit('emitGlobalState')
          },
          updateCommonMicroApps({ commit }, data) {
            commit('updateCommonMicroApps', data)
            commit('emitGlobalState')
          },
          updateUserMicroApps({ commit }, data) {
            commit('updateUserMicroApps', data)
            commit('emitGlobalState')
          },
          updateUserAuthorize({ commit }, data) {
            commit('updateUserAuthorize', data)
            commit('emitGlobalState')
          },
          addOpenPage({ commit }, data) {
            commit('addOpenPage', data)
            commit('emitGlobalState')
          },
          removePage({ commit }, id) {
            commit('removePage', id)
            commit('emitGlobalState')
          },
          removeOtherPage({ commit }, name) {
            commit('removeOtherPage', name)
            commit('emitGlobalState')
          },
          clearPage({ commit }) {
            commit('clearPage')
            commit('emitGlobalState')
          },
          upateComponentName({ commit }, data) {
            commit('upateComponentName', data)
            commit('emitGlobalState')
          },
        },
        mutations: {
          updateTitle(state, subtitle) {
            window.document.title = subtitle + '-' + state.title
          },
          setGlobalState(state, payload) {
            // eslint-disable-next-line
            state = Object.assign(state, payload)
          },
          // 通知父应用
          emitGlobalState(state) {
            if (props.setGlobalState) {
              props.setGlobalState(state)
            }
          },
          updateUser(state, data) {
            state.user = data
          },
          updatetoken(state, obj) {
            state.user.token = obj.token
            state.user.refreshToken = obj.refreshToken
            state.user.secretKey = obj.secretKey
            state.user.isUpdate = true
          },
          updateBusinessCode(state, businessCode) {
            state.businessCode = businessCode
          },
          updateMenu(state, data) {
            state.menu = data
          },
          updateCommonMicroApps(state, data) {
            state.commonMicroApps = data
          },
          updateUserMicroApps(state, data) {
            // state.commonMicroApps = []
            state.userMicroApps = data
            // 预加载子应用
            props.prefetchApps(data)
          },
          updateUserAuthorize(state, data) {
            state.userAuthorize = data
          },

          upateComponentName(state, data) {
            var obj = state.openPages.find(
              (item) =>
                item.appName == data.appName &&
                item.routerName == data.routerName
            )
            obj.componentName = data.componentName
          },

          addOpenPage(state, page) {
            state.openPages.push(page)
          },
          removePage(state, id) {
            // 如果是首页，不能关闭当前
            var index = state.openPages.findIndex((item) => item.id == id)
            if (index > 0) state.openPages.splice(index, 1)
          },
          removeOtherPage(state, current_id) {
            var item = state.openPages.find((item) => item.id == current_id)
            if (state.openPages.indexOf(item) == 0) {
              // 当前页是首页，相等于关闭所有
              state.openPages.splice(1, state.openPages.length)
            } else {
              state.openPages.splice(1, state.openPages.length, item)
            }
          },
          clearPage(state) {
            state.openPages.splice(1, state.openPages.length)
          },
        },
        getters: {
          microApps(state) {
            var allApps = state.commonMicroApps.concat(state.userMicroApps)
            return allApps.map((item) => {
              return {
                ...item,
                props: {
                  routerBase: item.activeRule, // 下发基础路由
                  getGlobalState: props.getGlobalState, // 下发getGlobalState方法
                },
              }
            })
          },
        },
      }
      store.registerModule('global', globalModule)
    } else {
      // 每次mount时，都同步一次父应用数据
      store.dispatch('global/initGlobalState', initState)
    }
  },
}
