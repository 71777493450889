<template>
  <div>
    <el-submenu v-if="data.type=='group'" :index="data.id" :class="{'menu-top-group':data.deep==1}">
      <template slot="title">
        <i v-if="data.deep==1">
          <img style="width:24px;height:24px; margin-right:6px;" :src="data.icon">
        </i>
        <span slot="title">{{data.label}}</span>
      </template>
      <gz-menu v-for="item in data.items" :key="item.id" :data="item"></gz-menu>
    </el-submenu>
    <el-menu-item v-else :index="data.id" @click="gotoPage(data)">
      <i v-if="data.deep==1">
        <img style="width:24px;height:24px;margin-right:6px;" :src="data.icon">
      </i>
      <span slot="title">
        {{data.label}}
      </span>

    </el-menu-item>
  </div>
</template>
<style lang="css">
.el-menu--collapse > div > .el-submenu > .el-submenu__title span {
  height: 0;
  width: 0;
  overflow: hidden;
  visibility: hidden;
  display: inline-block;
}
.el-menu--collapse
  > div
  > .el-submenu
  > .el-submenu__title
  .el-submenu__icon-arrow {
  display: none;
}
.menu-top-group>.el-submenu__title{
  font-weight: bold;
  color:#68686e;
}
</style>
<script>

export default {
  name: 'gz-menu',
  data () {
    return {

    }
  },
  props: {
    data: Object
  },
  methods: {
    gotoPage (item) {
      // var url = currentApp.activeRule + item.path
      var url = item.url
      history.pushState(null, url, url)
    },
  }
}
</script>