import Vue from 'vue'
import App from './App.vue'
import { mapState } from 'vuex'
import { store as commonStore } from 'yesweb-libs'
import { cookie,dateUtils } from 'yesweb-libs/utils'
import { store, globalStore } from './store'
import GZControls from './controls'
Vue.use(GZControls)
import qiankunHelper from "./qiankunHelper";
Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 }

import { LicenseManager } from 'ag-grid-enterprise'

LicenseManager.setLicenseKey(
  'garson_NDExNzQ4MTQxMzAwMA==4bad2f1303a91a73dcbb423b86f03998'
)

// eslint-disable-next-line
import { start } from 'qiankun'

import baseApps from './data/baseApps'

import { ajax } from 'yesweb-libs'

Vue.config.devtools = true
Vue.config.productionTip = false

// new Vue({
//   render: h => h(App),
// }).$mount('#app')
commonStore.globalRegister(store, {
  getGlobalState: globalStore.getGlobalState,
  prefetchApps: prefetchApps,
})

function prefetchApps(apps) {
  
  setTimeout(() => {
    // debugger
    qiankunHelper.prefetchApps(apps);  
  }, 1000);
  
}


const ajaxOption = {
  writeLog: true,
  actionLogin(message) {
    console.log('子应用：跳转到登录窗口:' + message)
    var loginURL = '/common/login'
    history.pushState(null, loginURL, loginURL)
  },
  actionError(o) {
    instance.$alert(o.message, o.title, {
      confirmButtonText: '确定',
      type: 'error',
    })
  },
}
ajax.init(store, ajaxOption)

Vue.prototype.$dateUtils= dateUtils
Vue.filter(
  'dateFormat',
  /**
   * 格式化日期字符串
   * @param {Date} date 需要格式化的日期类型，dayjs方式
   * @param {String} format  YYYY-MM-DD HH:mm:ss
   * @returns 格式化后的日期
   */
  function (date, format) {
    return dateUtils.dateFormat(date, format)
  }
)
Vue.prototype.$eventHub = Vue.prototype.$eventHub || new Vue()
const instance = new Vue({
  store,
  render: (h) => h(App),
  created() {
    store.dispatch('global/updateCommonMicroApps', baseApps)
  },
  computed: {
    // 通过global获取user的信息
    ...mapState('global', {
      businessCode: (state) => state.businessCode,
      user: (state) => state.user,
    }),
    isUpdate() {
      return this.user.isUpdate
    },
  },
  watch: {
    isUpdate(newval, oldVal) {
      if (newval) {
        if (this.user.isLogin === true) {
          var days = undefined
          if (this.user.remember) {
            days = 7
          }
          cookie.setCookie('businessCode', this.businessCode, days)
          cookie.setCookie('token', this.user.token, days)
          cookie.setCookie('refreshToken', this.user.refreshToken, days)
          cookie.setCookie('secretKey', this.user.secretKey, days)
          cookie.setCookie('remember', this.user.remember, days)
        } else {
          cookie.delCookie('businessCode')
          cookie.delCookie('token')
          cookie.delCookie('refreshToken')
          cookie.delCookie('secretKey')
          cookie.delCookie('remember')
        }
        this.user.isUpdate = false
      }
    },
  },
}).$mount('#app')

// // 定义loader方法，loading改变时，将变量赋值给App.vue的data中的isLoading
// function loader(loading) {
//   if (instance && instance.$children) {
//     // instance.$children[0] 是App.vue，此时直接改动App.vue的isLoading
//     instance.$children[0].isLoading = loading
//   }
// }
// // 给子应用配置加上loader方法
// const apps = baseApps.map((item) => {
//   return {
//     ...item,
//     container: '#subcontent', // 子应用挂载的div
//     props: {
//       routerBase: item.activeRule, // 下发基础路由
//       getGlobalState: globalStore.getGlobalState, // 下发getGlobalState方法
//     },
//     loader,
//   }
// })
// console.log(apps)

// registerMicroApps(apps, {
//   beforeLoad: (app) => {
//     console.log('before load app.name====>>>>>', app.name)
//   },
//   beforeMount: [
//     (app) => {
//       console.log('[LifeCycle] before mount %c%s', 'color: green;', app.name)
//     },
//   ],
//   afterMount: [
//     (app) => {
//       console.log('[LifeCycle] after mount %c%s', 'color: green;', app.name)
//     },
//   ],
//   afterUnmount: [
//     (app) => {
//       console.log('[LifeCycle] after unmount %c%s', 'color: green;', app.name)
//     },
//   ],
// })
// console.log('==================qiankun start()')
// setDefaultMountApp('/common')

start()
