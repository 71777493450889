import { initGlobalState } from 'qiankun'
import Vue from 'vue'
import config from '../config'

import { cookie } from 'yesweb-libs/utils'
// 父应用的初始state
// Vue.observable是为了让initialState变成可响应：https://cn.vuejs.org/v2/api/#Vue-observable。
let token = cookie.getCookie('token')
let isLogin = false
if ((token || '') !== '') isLogin = true
const initialState = Vue.observable({
  client: config.client,
  serverHost: config.serverHost,
  businessCode: config.businessCode || cookie.getCookie('businessCode'),
  commonMicroApps: [],
  userMicroApps: [],
  userAuthorize:[],
  menu: [],
  openPages: [],
  title: 'YESWEB开发框架',
  user: {
    isLogin: isLogin,
    name: '',
    token: cookie.getCookie('token'),
    refreshToken: cookie.getCookie('refreshToken'),
    secretKey: cookie.getCookie('secretKey'),
    remember: cookie.getCookie('remember'),
  },
})
// console.log(config)

const actions = initGlobalState(initialState)

actions.onGlobalStateChange((newState, prev) => {
  // state: 变更后的状态; prev 变更前的状态
  // console.log('main change', JSON.stringify(newState), JSON.stringify(prev))

  for (const key in newState) {
    initialState[key] = newState[key]
  }
})

// 定义一个获取state的方法下发到子应用
actions.getGlobalState = (key) => {
  // 有key，表示取globalState下的某个子级对象
  // 无key，表示取全部

  return key ? initialState[key] : initialState
}

export default actions
