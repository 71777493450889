import GZControls from './controls'
import { loadMicroApp, prefetchApps } from 'qiankun'

export default {
  prefetchApps: (app) => {
    return prefetchApps(app)
  },
  loadMicroApp: (app) => {
    if (!app.props.GZControls) {
      app.props.GZControls = GZControls
      if (!app.props.prefetchApps) app.props.prefetchApps = prefetchApps
    }
    return loadMicroApp(app)
  },
}
